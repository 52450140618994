/* ./src/effects.css */

.shadow-xl {
    box-shadow: 0 0 50px #CACACA;
}

.shadow-xl:hover {
    box-shadow: 0 0 100px #a3a3a3;
}

summary::-webkit-details-marker {
    display: none;
    /* Elimina la flecha de Chrome */
}