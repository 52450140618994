@tailwind base;
@tailwind components;
@tailwind utilities;

/* background-color: #E5E7EB on root */
/* :root {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
 */
@font-face {
  font-family: 'aeronaut';
  src: url('./fonts/aeronaut.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'aeronaut';
  src: url('./fonts/aeronaut.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

.aeronaut-poi-regular {
  font-family: 'aeronaut', sans-serif;
  font-weight: normal;
}

/* root all font aeronaut */
/* body {
  font-family: 'aeronaut', sans-serif;
} */